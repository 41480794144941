import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Logo from "../assets/icons/logo.svg"
import Hamburger from "../components/Hamburger"
import { faPhoneAlt, faClock } from "@fortawesome/free-solid-svg-icons"
import { Icon } from "./Icon"
import { RedSpan } from "./RedSpan"

const NavWrapper = styled.nav`
  padding: 10px 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkBlue};
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: -0px 10px 11px -13px rgba(255, 255, 255, 0.2);
`
const BeforeNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #222222;
  padding: 10px;
  color: white;
  height: 50px;
  font-size: 0.9rem;
  a {
    margin-left: 10px;
    margin-right: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 590px) {
    display: none;
    visibility: hidden;
  }
`
const StyledParagraph = styled.p`
  margin-left: 10px;
  margin-bottom: 0;
`
const StyledList = styled.ul`
  margin: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  padding-right: 0.4vw;
  list-style: none;
  height: 100%;

  li {
    padding: 0 15px;
  }
  a {
    transition: 0.2s ease-in-out opacity;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-grow: 2;
    letter-spacing: 3px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    &:hover {
      opacity: 0.7;
      color: white;
      text-decoration: none;
    }
  }
  @media (min-width: 1600px) {
    font-size: 18px;
  }
  @media (max-width: 950px) {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 100%;
    height: 100vh;
    width: 100vw;
    transform: translateX(-100%);
    transition: 0.2s transform;
    background-color: ${({ theme }) => theme.darkBlue};

    li {
      padding: 2vh 0.4vw;
    }
    ${({ isActive }) =>
      isActive &&
      css`
        display: flex;
        transform: translateX(0);
      `};
  }
`

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require

  require("smooth-scroll")('a[href*="#"]')
}
const Navigation = ({ scroll }) => {
  const [active, setActive] = useState(false)

  const ToggleMenu = () => {
    setActive(!active)
  }

  return (
    <>
      <BeforeNav>
        <Icon icon={faPhoneAlt} size="lg" />
        <a href="tel:+123863336"> 12 386 33 36 &nbsp;</a>
        <Icon icon={faClock} size="lg" />
        <StyledParagraph>
          PON — PT: 8:00 — 17:00 SOBOTA : 8:00 — 13:00
        </StyledParagraph>
      </BeforeNav>
      <NavWrapper>
        <Link to="/">
          <img src={Logo} />
        </Link>
        <Hamburger isActive={active} onClick={() => ToggleMenu()} />
        <StyledList isActive={active}>
          <li>
            <Link to="/" onClick={() => ToggleMenu()}>
              Strona główna
            </Link>
          </li>
          <li>
            <a href="/#o-nas" onClick={() => ToggleMenu()}>
              O nas
            </a>
          </li>
          <li>
            <a href="/#kontakt" onClick={() => ToggleMenu()}>
              Kontakt
            </a>
          </li>
          <li>
            <Link to="/aktualnosci">
              <RedSpan>Aktualności</RedSpan>
            </Link>
          </li>
        </StyledList>
      </NavWrapper>
    </>
  )
}
export default Navigation
