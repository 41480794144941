import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Icon } from "./Icon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { RedSpan } from "./RedSpan"

const StyledFooter = styled.footer`
  display: grid;
  width: 100%;
  position: relative;
  bottom: 0;
  background-color: ${({ theme }) => theme.darkBlue};
  margin-top: -10px;
`
const InnerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;

  h4 {
    letter-spacing: 2px;
    font-weight: 400;
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: 2px solid white;
    margin-bottom: 10px;
  }
`
const StyledList = styled.ul`
  margin: 0 auto;
  list-style: none;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
`
const CopyRights = styled.div`
  border-top: 1px solid white;
  font-size: 0.8rem;
  padding: 10px;
  color: #777;

  @media (max-width: 800px) {
    width: 80%;
    margin: 0 auto;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`
const SocialIcon = styled.a`
  color: ${({ theme }) => theme.white};
  margin-left: 10px;
  &:hover {
    color: #f1f1f1;
  }
`

const Year = new Date().getFullYear()
const Footer = () => (
  <StyledFooter>
    <InnerWrapper>
      <StyledList>
        <li>
          <StyledLink to="/#o-nas">O nas</StyledLink>
        </li>
        <li>
          <StyledLink to="/aktualnosci">
            <RedSpan>Aktualności</RedSpan>
          </StyledLink>
        </li>

        <li>
          <StyledLink to="/#contact">Kontakt</StyledLink>
          <SocialIcon
            href="https://www.facebook.com/Serwis-Olejowy-SAGAN-111901730465518/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </SocialIcon>
        </li>
      </StyledList>
      <CopyRights>Copyright © {Year} SCD Studio Daniel Staszek</CopyRights>
    </InnerWrapper>
  </StyledFooter>
)
export default Footer
