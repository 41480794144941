/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { theme } from "../theme/theme"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import "./layout.css"
import MessengerCustomerChat from "react-messenger-customer-chat"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const StyledMain = styled.main`
    margin: 0 auto;
  `

  return (
    <ThemeProvider theme={theme}>
      <>
        <Navigation siteTitle={data.site.siteMetadata.title} />
        <StyledMain>{children}</StyledMain>
        <MessengerCustomerChat
          pageId={process.env.GATSBY_FB_PAGE_ID}
          appId={process.env.GATSBY_FB_APP_ID}
          htmlRef={process.env.GATSBY_FB_HTML_REF}
        />

        <Footer />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
